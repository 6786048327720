import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Classes from "./auth.module.scss";
import { Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CloseEye from "../../assets/images/icons/closeEye.svg";
import OpenEye from "../../assets/images/icons/openEye.svg";
import { Container } from "react-bootstrap";
import { signin } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import { thunkLogin } from "../../store/_actions/user_action";
import SigninGoogleButton from "./SigninGoogleButton";
// import Logo from "../../assets/images/section/whiteLogo.svg";
import Logo from "../../assets/images/section/beta-logo.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { Helmet } from 'react-helmet';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { address: walletAddress } = useAccount();
  const [loading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { open } = useWeb3Modal();

  const showPassword = (type) => {
    setPasswordType(type);
  };

  useEffect(() => {
    if (auth?.email) {
      navigate("/");
    }
  }, []);

  const signinUser = async (data) => {
    try {
      setIsLoading(true);
      let user = await signin(data);
      if (user && user.data.success === true) {
        localStorage.setItem("authenticationToken", user?.data?.data?.token);
        console.log(user?.data?.data, "user?.data?.data=.");
        localStorage.setItem("userInfo", JSON.stringify(user?.data?.data));
        dispatch(
          thunkLogin(user?.data?.data, () => {
            alertMessage("success", `${user?.data?.message}`);
            setIsLoading(false);
            setTimeout(() => {
              navigate("/get-started");
            }, 1000);
          })
        );
      } else {
        alertMessage("error", `${user?.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setIsLoading(false);
      console.log('error.response', error);
    }
  };
  return (
   
    <div className={Classes.login}>
       <Helmet>
        <title>CharityNFT Login</title>
        <meta name="title" content="CharityNFT Login"/>
        <meta name="description" content="Make new friends. Browse, create, buy, sell, auction, and donate NFTs using CharityNFT today." />
        <meta name="keywords" content="Charity NFTs, NFT donations, Blockchain charity projects, Crypto philanthropy, Digital assets for charity, NFT fundraising, Support charity through NFTs, Charity crypto donations, Non-fungible tokens for social impact, Ethical NFTs, NFT charitable initiatives, Donate NFTs to charity, Charity blockchain solutions, Philanthropy through NFTs, NFT marketplace for charity, Social good NFTs, NFT charity events, Crypto giving platforms, NFT art for charity, Charity-driven NFTs" />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/login`} />
      </Helmet>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={13}></Col>
          <Col lg={11} md={24} sm={24} xs={24}>
            <Link
              to="/"
              className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <h2 className={Classes.heading}>Log in</h2>
                <p className={Classes.desc}>
                  Log in your account
                </p>
              </div>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    // .email('Email is invalid.')
                    .trim()
                    .max(50, "Email or username must be at most 20 characters.")
                    .required("Email or username is required."),
                  password: Yup.string()
                    .trim()
                    .max(20, "Password must be at most 20 characters.")
                    .min(8, "Password must be 8 characters at minimum.")
                    .required("Password is required."),
                })}
                onSubmit={(values, { resetForm }) => {
                  const data = {
                    password: values.password,
                    email: values.email,
                  };
                  signinUser(data);
                  //resetForm({ values: '' });
                }}
                textAlign="center"
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                    <div className={Classes.formFields}>
                      <div className="mb-3">
                        <div className={`${Classes.inputOuter}`}>
                          <input
                            className="pe-3"
                            type="email"
                            name="email"
                            placeholder="Enter username or email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                        </div>
                        <span
                          className="mt-1 d-block"
                          size={"small"}
                          style={{ color: "red" }}
                        >
                          {errors.email && touched.email ? (
                            <Fragment>{errors.email}</Fragment>
                          ) : null}
                        </span>
                      </div>

                      <div className="mb-3">
                        <div className={`${Classes.inputOuter}`}>
                          <input
                            className="pe-3"
                            type={passwordType}
                            name="password"
                            placeholder="Enter password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                          />
                          {passwordType === "password" ? (
                            <img
                              onClick={() => showPassword("text")}
                              src={CloseEye}
                              alt="icon"
                            />
                          ) : (
                            <img
                              onClick={() => showPassword("password")}
                              src={OpenEye}
                              alt="icon"
                            />
                          )}
                        </div>

                        <span
                          className="mt-1 d-block"
                          size={"small"}
                          style={{ color: "red" }}
                        >
                          {errors.password && touched.password ? (
                            <Fragment>{errors.password}</Fragment>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mb-3">
                      <Link className={Classes.link} to="/forget-password">
                        Forgot Password?
                      </Link>
                    </div>

                    <div className={Classes.btnOuter}>
                      <button
                        className={`${Classes.blueBtn} mb-3`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Log in"
                        )}
                      </button>
                      <div className="mb-4">
                        <Link className={Classes.googleBtn} to="/sign-up">
                          Sign up
                        </Link>
                      </div>
                      {/* <div className="position-relative d-flex justify-content-center flex-column mb-4">
                        <img
                          className={`${Classes.line} position-absolute`}
                          src={Line}
                          alt="img"
                        />
                        <div className={`d-flex justify-content-center`}>
                          <span className={Classes.break}>Or</span>
                        </div>
                      </div>
                      <SigninGoogleButton /> */}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
