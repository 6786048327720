import React, { Fragment, useState } from "react";
import Classes from "../Login/auth.module.scss";
import { Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import CloseEye from "../../assets/images/icons/closeEye.svg";
import OpenEye from "../../assets/images/icons/openEye.svg";
import { verifyAccount } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
// import Logo from "../../assets/images/section/whiteLogo.svg";
import Logo from "../../assets/images/section/beta-logo.svg";


const SetPasswordComp = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const showPassword = (type) => {
    setPasswordType(type);
  };
  const showPassword2 = (type) => {
    setPasswordType2(type);
  };

  const setupAccountPassword = async (data) => {
    try {
      setIsLoading(true);
      const user = await verifyAccount(data, token);
      if (user && user.data.success === true) {
        setTimeout(() => {
          alertMessage("success", "Account verified successfully.");
          setIsLoading(false);
          navigate("/login");
        }, 2000);
      } else {
        alertMessage("error", `${user?.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setIsLoading(false);
      console.log("error.response", error.response.message);
    }
  };

  return (
    <div className={`${Classes.login} ${Classes.setpassword}`}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={13} md={24} sm={24} xs={24}></Col>
          <Col lg={11} md={24} sm={24} xs={24}>
            <Link
              to="/"
              className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <h2 className={Classes.heading}>Set Password</h2>
                <p className={Classes.desc}>Enter your password</p>
              </div>

              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .trim()
                    .matches(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,20}$/,
                      "Password must contain at least 8 characters including one lowercase letter, one uppercase letter, and one numeric digit."
                    )
                    .max(
                      20,
                      "Password should not contain more than 15 characters."
                    )
                    .min(8, "Password must have at least 8 characters.")
                    .required("Password is required."),
                  confirmPassword: Yup.string()
                    .required("Confirm password is required.")
                    .oneOf(
                      [Yup.ref("password"), null],
                      "Both passwords need to be the same."
                    ),
                })}
                onSubmit={(values, { resetForm }) => {
                  const data = {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                  };
                  setupAccountPassword(data);
                  //resetForm({ values: '' });
                }}
                textAlign="center"
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                    <div className={Classes.formFields}>
                      <div className="mb-3">
                        <div className={`${Classes.inputOuter}`}>
                          <input
                            className="pe-3"
                            type={passwordType}
                            name="password"
                            placeholder="Enter password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                          />
                          {passwordType === "password" ? (
                            <img
                              onClick={() => showPassword("text")}
                              src={CloseEye}
                              alt="icon"
                            />
                          ) : (
                            <img
                              onClick={() => showPassword("password")}
                              src={OpenEye}
                              alt="icon"
                            />
                          )}
                        </div>

                        <span
                          className="mt-1 d-block"
                          size={"small"}
                          style={{ color: "red" }}
                        >
                          {errors.password && touched.password ? (
                            <Fragment>{errors.password}</Fragment>
                          ) : null}
                        </span>
                      </div>
                      <div className="mb-3">
                        <div className={`${Classes.inputOuter}`}>
                          <input
                            className="pe-3"
                            type={passwordType2}
                            name="confirmPassword"
                            placeholder="Re-enter password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.confirmPassword}
                          />
                          {passwordType2 === "password" ? (
                            <img
                              onClick={() => showPassword2("text")}
                              src={CloseEye}
                              alt="icon"
                            />
                          ) : (
                            <img
                              onClick={() => showPassword2("password")}
                              src={OpenEye}
                              alt="icon"
                            />
                          )}
                        </div>

                        <span
                          className="mt-1 d-block"
                          size={"small"}
                          style={{ color: "red" }}
                        >
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <Fragment>{errors.confirmPassword}</Fragment>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div className={Classes.btnOuter}>
                      <button
                        className={`${Classes.blueBtn} mb-4`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Done"
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SetPasswordComp;
