import React from "react";
import Classes from "../Login/auth.module.scss";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Logo from "../../assets/images/section/whiteLogo.svg";
import Logo from "../../assets/images/section/beta-logo.svg";


function EmailVerificationComp() {
  return (
    <div className={`${Classes.login} ${Classes.signup}`}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={13} md={24} sm={24} xs={24}>
            
          </Col>
          <Col lg={11} md={24} sm={24} xs={24}>
          <Link
              to="/"
              className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <h2 className={Classes.heading}>Email Verification</h2>
                <p className={`${Classes.desc} mb-2`}>
                  Check your email for verification
                </p>
              </div>
              <p className={Classes.desc2}>
                In your email, you will find a link to verify your account. Log
                in by clicking on the link.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EmailVerificationComp;
