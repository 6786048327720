import React, { Fragment, useState } from 'react';
import Classes from '../Login/auth.module.scss';
import { Col, Row } from 'antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { Container } from 'react-bootstrap';
import { register } from '../../services/auth.service';
import { alertMessage } from '../../constants/alertMessage';
// import Logo from '../../assets/images/section/whiteLogo.svg';
import Logo from "../../assets/images/section/beta-logo.svg";


const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);

  const signupUser = async (data) => {
    try {
      setIsLoading(true);
      const user = await register(data);

      if (user && user.data.success === true) {
        setTimeout(() => {
          alertMessage('success', `${user?.data?.message}`);
          setIsLoading(false);
          navigate('/email-verification');
        }, 2000);
      } else {
        alertMessage('error', `${user?.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      alertMessage('error', 'Something went wrong.');
      setIsLoading(false);

      console.log('error.response', error.response.message);
    }
  };

  return (
    <div className={`${Classes.login} ${Classes.signup}`}>
      <Container>
        <Row className='d-flex justify-content-center align-items-center'>
          <Col lg={13} md={24} sm={24} xs={24}>
          </Col>
          <Col lg={11} md={24} sm={24} xs={24}>
          <Link to='/' className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}>
              <img src={Logo} alt='logo' />
            </Link>
            <div className={Classes.formOuter}>
              <div className='mb-3'>
                <h2 className={Classes.heading}>Sign up</h2>
                <p className={Classes.desc}>Sign up your account</p>
              </div>

              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Email is invalid.')
                    .trim()
                    .max(50, 'Email must be at most 20 characters.')
                    .required('Email is required.'),
                })}
                onSubmit={(values, { resetForm }) => {
                  const data = {
                    email: values.email,
                    role: 'User',
                  };
                  signupUser(data);
                  //resetForm({ values: '' });
                }}
                textAlign='center'
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className='mt-8 space-y-6'
                    noValidate
                    autoComplete='off'
                  >
                    <div className={`${Classes.formFields} mb-4`}>
                      <div className={`${Classes.inputOuter}`}>
                        <input
                          id='email-address'
                          name='email'
                          type='email'
                          placeholder='Email address'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                      </div>
                      <span
                        size={'small'}
                        style={{ color: 'red' }}
                        className='mt-1 d-block'
                      >
                        {errors.email && touched.email ? (
                          <Fragment>{errors.email}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <div className={Classes.btnOuter}>
                      <button
                        className={`${Classes.blueBtn} mb-3`}
                        type='submit'
                        disabled={loading}
                      >
                        {loading ? (
                          <div className='d-flex justify-content-center align-items-center'>
                            <ThreeDots
                              height='30'
                              width='30'
                              radius='9'
                              color='white'
                              ariaLabel='three-dots-loading'
                              wrapperStyle={{}}
                              wrapperClassName=''
                              visible={true}
                            />
                          </div>
                        ) : (
                          'Verify'
                        )}
                      </button>
                      <div className='mb-4'>
                        <Link className={Classes.googleBtn} to='/login'>
                          Log in
                        </Link>
                      </div>
                      {/* <div className='position-relative d-flex justify-content-center flex-column mb-4'>
                        <img
                          className={`${Classes.line} position-absolute`}
                          src={Line}
                          alt='img'
                        />
                        <div className={`d-flex justify-content-center`}>
                          <span className={Classes.break}>Or</span>
                        </div>
                      </div>
                      <SignupGoogleButton
                      // loading={googleLoading}
                      /> */}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
