import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "../Login/auth.module.scss";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import { alertMessage } from "../../constants/alertMessage";
import { Link } from "react-router-dom";
// import Logo from "../../assets/images/section/whiteLogo.svg";
import Logo from "../../assets/images/section/beta-logo.svg";

import CloseEye from "../../assets/images/icons/closeEye.svg";
import OpenEye from "../../assets/images/icons/openEye.svg";

const Security = () => {
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const showPassword = (type) => {
    setPasswordType(type);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password == "Pa$$w0rd!") {
      localStorage.setItem("passForPROD", "Pa$$w0rd!");
      navigate("/");
    } else {
      alertMessage("error", "Wrong Password! Please try again.");
    }
  };

  return (
    <div className={`${Classes.login} ${Classes.setpassword}`}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={13} md={24} sm={24} xs={24}></Col>
          <Col lg={11} md={24} sm={24} xs={24}>
            <Link
              to="/"
              className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <h2 className={Classes.heading}>Enter Password</h2>
              </div>

              <div className={Classes.formFields}>
                <div className="mb-3">
                  <div className={`${Classes.inputOuter}`}>
                    <input
                      className="pe-3"
                      type={passwordType}
                      name="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordType === "password" ? (
                      <img
                        onClick={() => showPassword("text")}
                        src={CloseEye}
                        alt="icon"
                      />
                    ) : (
                      <img
                        onClick={() => showPassword("password")}
                        src={OpenEye}
                        alt="icon"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={Classes.btnOuter}>
                <button
                  className={`${Classes.blueBtn} mb-4`}
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Security;
